/* eslint-disable camelcase, max-classes-per-file */

/** Инициализация window.dataLayer на случай, если скрипт GTM затупил, и объект до сих пор не определён */
window.dataLayer = window.dataLayer ?? [];

/**
 * Перечисление доступных событий
 * Все новые события следует добавлять сюда, иначе событие не будет отправлено
 */
const GoogleTagManagerEvent = Object.freeze({
    SEND_LOGIN_EVENTS: 'send_login_events',
    SEND_REGISTRATION_EVENTS: 'send_registration_events',
    SET_USER_ID: 'set_user_id',
    SEND_USER_PROPERTIES: 'send_user_properties',
});

/** Просто красивая именная ошибка ;) */
class GoogleTagManagerError extends Error {
    constructor(message) {
        super(message);
        this.name = 'GoogleTagManagerError';
    }
}

/** API для отстрела событий в GTM */
class GoogleTagManager {
    #knownEvents = Object.values(GoogleTagManagerEvent);

    /**
     * Валидатор имён событий
     * @param {string} event
     * @returns {void}
     * @private
     */
    #validatePayload({ event }) {
        if (!this.#knownEvents.includes(event)) {
            throw new GoogleTagManagerError(`Unknown event "${event}". Did you forget to include it in the GoogleTagManagerEvent enumeration?`);
        }
    }

    /**
     * Универсальная функция для отстрела любых событий с любыми данными
     * @param {Record<string, unknown> & { event: string }} payload
     * @returns {void}
     * @private
     */
    #push(payload) {
        this.#validatePayload(payload);

        window.dataLayer.push(payload);
        console.raw(
            '%cGoogleTagManager%c',
            'background-color: #017fd2; border-radius: 3px; color: white; padding: 2px 4px',
            '',
            payload,
        );
    }

    /**
     * Функция для отправки событий, связанных с логином
     * @param {Record<string, unknown> & { event_name: string }} payload
     * @returns {void}
     * @private
     */
    #handleLoginEvent(payload) {
        this.#push({ event: GoogleTagManagerEvent.SEND_LOGIN_EVENTS, event_cat: 'login', ...payload });
    }

    /**
     * Функция для отправки событий, связанных с регистрацией
     * @param {Record<string, unknown> & { event_name: string }} payload
     * @returns {void}
     * @private
     */
    #handleRegEvent(payload) {
        this.#push({ event: GoogleTagManagerEvent.SEND_REGISTRATION_EVENTS, event_cat: 'registration', ...payload });
    }

    /**
     * Метод для отстрела нажатия на кнопку "Войти" на форме логина
     * @returns {void}
     */
    handleLoginButtonClick() {
        this.#handleLoginEvent({ event_name: 'button' });
    }

    /**
     * Метод для отстрела события фокуса для поля ввода номера_телефона/email на форме логина
     * @returns {void}
     */
    handleLoginEmailPhoneFieldFocus() {
        this.#handleLoginEvent({ event_name: 'phone_email_field' });
    }

    /**
     * Метод для отстрела ошибки в процессе логина
     * @param {string} login_error_text
     * @param {string|null|undefined} login_social_method
     * @returns {void}
     */
    handleLoginError(login_error_text, login_social_method = null) {
        this.#handleLoginEvent({
            event_name: 'error',
            login_error_text,
            login_social_method,
        });
    }

    /**
     * Метод для отстрела нажатия на кнопку закрытия формы логина
     * @returns {void}
     */
    handleLoginExitClick() {
        this.#handleLoginEvent({ event_name: 'form_exit' });
    }

    /**
     * Метод для отстрела открытия формы логина
     * @returns {void}
     */
    handleLoginOpen() {
        this.#handleLoginEvent({ event_name: 'form_view' });
    }

    /**
     * Метод для отстрела события фокуса для поля ввода пароля на форме логина
     * @returns {void}
     */
    handleLoginPasswordFieldFocus() {
        this.#handleLoginEvent({ event_name: 'password_field' });
    }

    /**
     * Метод для отстрела события клика на ссылку перехода на форму регистрации в форме логина
     * @returns {void}
     */
    handleLoginToRegisterClick() {
        this.#handleLoginEvent({ event_name: 'to_registration' });
    }

    /**
     * Метод для отстрела нажатия на кнопку "Забыли пароль?" на форме логина
     * @returns {void}
     */
    handleLoginRestorePasswordClick() {
        this.#handleLoginEvent({ event_name: 'to_restore_password' });
    }

    /**
     * Метод для отстрела нажатия на кнопку социальной сети на форме логина
     * @returns {void}
     */
    handleLoginSocialButtonClick(registration_social_method) {
        this.#handleLoginEvent({ event_name: 'social_login', registration_social_method });
    }

    /**
     * Метод для отстрела успешного логина
     * @param {string|null|undefined} registration_social_method
     * @returns {void}
     */
    handleLoginSuccess(user_id = null, login_social_method = null) {
        this.#handleLoginEvent({ event_name: 'success', user_id, login_social_method });
    }

    /**
     * Метод для отстрела нажатия на кнопку "Зарегистрироваться"
     * @returns {void}
     */
    handleRegButtonClick() {
        this.#handleRegEvent({ event_name: 'button_click' });
    }

    /**
     * Метод для отстрела закрытия формы регистрации по нажатию на крестик
     * @returns {void}
     */
    handleRegCloseButtonClick() {
        this.#handleRegEvent({ event_name: 'form_exit' });
    }

    /**
     * Метод для отстрела ошибки в процессе регистрации
     * @param {string} registration_error_text
     * @param {string|null|undefined} promocode
     * @param {string|null|undefined} registration_social_method
     * @returns {void}
     */
    handleRegError(registration_error_text, promocode = null, registration_social_method = null) {
        this.#handleRegEvent({
            event_name: 'error',
            registration_error_text,
            promocode,
            registration_social_method,
        });
    }

    /**
     * Метод для отстрела открытия формы регистрации
     * @returns {void}
     */
    handleRegOpen() {
        this.#handleRegEvent({ event_name: 'form_view' });
    }

    /**
     * Метод для отстрела успешной регистрации
     * @param {string|null|undefined} promocode
     * @param {number|null|undefined} user_id
     * @param {string|null|undefined} registration_social_method
     * @returns {void}
     */
    handleRegSuccess(promocode = null, user_id = null, registration_social_method = null) {
        this.#handleRegEvent({
            event_name: 'success',
            promocode,
            user_id,
            registration_social_method,
        });
    }

    /**
     * Метод для отстрела id пользователя
     * @param {number|null|undefined} user_id
     * @returns {void}
     */
    setUserId(user_id = null) {
        this.#push({ event: GoogleTagManagerEvent.SET_USER_ID, user_id });
    }

    /**
     * Метод для отстрела свойств пользователя
     * @param {Object.<string, string>} props
     * @returns {void}
     */
    setUserProperties(props) {
        for (const [name, value] of Object.entries(props)) {
            this.#push({
                event: GoogleTagManagerEvent.SEND_USER_PROPERTIES,
                user_property_name: name,
                [name]: value,
            });
        }
    }
}

/**
 * Единственный экземпляр API для отстрела событий в GTM
 * @singleton
 */
export const googleTagManager = new GoogleTagManager();

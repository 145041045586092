/* eslint-disable no-underscore-dangle */
/**
 *  @implements Storage
 */
export default class InMemoryStorage {
    constructor() {
        this._storage = {};
    }

    get length() {
        return Object.keys(this._storage).length;
    }

    key(index) {
        if (index > this.length) {
            return null;
        }

        return Object.keys(this._storage)[index];
    }

    getItem(key) {
        if (key in this._storage) {
            return this._storage[key];
        }

        return null;
    }

    setItem(key, value) {
        this._storage[key] = value;
    }

    removeItem(key) {
        delete this._storage[key];
    }

    clear() {
        this._storage = {};
    }
}

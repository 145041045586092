export const CASINO_CATEGORIES = Object.freeze({
    FOR_ADVERTISING: -12,
    PROMOTED: -11,
    FAVORITES: -10,
    ALL: 1,
    POPULAR: 2,
    LIVE: 3,
    NEW: 4,
    SLOTS: 5,
    TABLE: 6,
    VIDEO_POKER: 7,
    JACKPOTS: 8,
    SCRATCH_CARDS: 9,
    ROULETTE: 10,
    MULTI_PLAYER: 11,
    BLACK_JACKS: 12,
    VIRTUAL_SPORTS: 13,
    OTHER: 14,
    DEMI_GODS: 15,
    DROPS_AND_WIN: 16,
    TOP: 17,
    SPIN_FOR_WIN: 18,
    BONUS_BUY: 19,
    GAME_SHOWS: 20,
    RECOMMEND: 21,
    PROMO_ONE: 22,
    PROMO_TWO: 23,
    ONE_WIN: 64,
});

export const CASINO_CATEGORIES_MAP = Object.freeze(
    Object.fromEntries(Object.entries(CASINO_CATEGORIES).map(([key, value]) => [value, key])),
);

export const CASINO_WIDGET = Object.freeze({
    ASIDE: 'aside',
    WARM: 'warm',
    VIRT_LIST: 'virt_sport',
});

export const CasinoGameType = Object.freeze({
    VIRTUAL: 'CasinoGameType:virtual',
    PROMOTED: 'CasinoGameType:promoted',
    ONE_WIN: 'CasinoGameType:one-win',
});

export const getDefaultPromoValue = () => ({
    freespin: null,
    games: [],
    win: null,
});

export const CasinoGameOwnerName = Object.freeze({
    ONE_WIN: '1win',
});

export default async () => {
    // delete Element.prototype.scrollTo;
    if (!Element.prototype.scrollTo) {
        console.info('!!! Import scrollTo polyfill');
        try {
            Element.prototype.scrollTo = function scrollTo(left = 0, top = 0) {
                this.scrollLeft = left;
                this.scrollTop = top;
            };
        } catch (e) {
            console.error('!!! Error import scrollTo polyfill', e);
        }
    }
};

import { AppStorage, USE_ERUDA_CONSOLE } from '@/plugins/AppStorage';

const initEruda = async () => {
    if (!AppStorage.getOr(USE_ERUDA_CONSOLE, false)) {
        return;
    }

    const eruda = await import('eruda');
    eruda.default.init();
};

initEruda();

import ResizeObserver from './ResizeObserver';
import ScrollTo from './ScrollTo';
import SmoothScroll from './SmoothScroll';

const resolve = async (polyfillLoader) => {
    try {
        await polyfillLoader();
    } catch (e) {
        console.error('polyfill reject', e);
    }
};

export default () => Promise.all([
    resolve(ScrollTo),
    resolve(SmoothScroll),
    resolve(ResizeObserver),
]);

export default async () => {
    if (!('scrollBehavior' in document.documentElement.style)) {
        console.info('!!! Import SmoothScroll polyfill');
        try {
            const { polyfill } = (await import('smoothscroll-polyfill')).default;

            polyfill();
        } catch (e) {
            console.error('!!! Error import SmoothScroll polyfill', e);
        }
    }
};

/* eslint-disable no-underscore-dangle */
import Vue from 'vue';

import InMemoryStorage from '@/plugins/AppStorage/inMemoryStorage';

const CURRENT_VERSION = 0; // increment this for force reset on clients
const GLOBAL_PREFIX = '1w_';

export class AppStorage {
    constructor({
        currentVersion = CURRENT_VERSION,
        prefix = GLOBAL_PREFIX,
    } = {}) {
        this.currentVersion = currentVersion;
        this.prefix = prefix;

        this.isNativeLocalStorage = AppStorage.checkAvailabilityLocalStorage;

        if (this.isNativeLocalStorage) {
            this._storage = window.localStorage;
        } else {
            this._storage = new InMemoryStorage();
        }

        /* For storage reactivity
           Use reactiveStorageObject directly in components to have possibility to use reactivity with local storage.
           Example: ... AppStorage.reactiveStorageObject[REGISTER_FORM] ...
        */
        const observableStorageObject = Vue.observable({
            reactiveStorageObject: {
                ...this._storage,
            },
        });
        this.reactiveStorageObject = new Proxy(observableStorageObject, {
            get: (obj, key) => {
                // To provide convenience and to avoid boilerplate code in components
                // reactive storage must return Object with accessible properties or undefined if none found
                const storageKey = this._getKeyName(key);
                try {
                    const storage = obj.reactiveStorageObject[storageKey];
                    return storage ? JSON.parse(storage) : undefined;
                } catch (err) {
                    console.error(`An error occurred while getting previously stored value by key "${key}"`, { err });
                    return undefined;
                }
            },
            set: (obj, key, value) => {
                const storageKey = this._getKeyName(key);
                Vue.set(obj.reactiveStorageObject, storageKey, value);
                return true;
            },
            deleteProperty: (obj, key) => {
                const storageKey = this._getKeyName(key);
                Vue.delete(obj.reactiveStorageObject, storageKey);
                return true;
            },
        });
        /* -- For storage reactivity -- */

        this._checkVersion();
    }

    get(key, { prefix } = {}) {
        const storageKey = this._getKeyName(key, prefix);

        try {
            const item = this._storage.getItem(storageKey);
            return JSON.parse(item);
        } catch (err) {
            console.error(`An error occurred while getting previously stored value by key "${key}"`, { err });
            throw err;
        }
    }

    getOr(key, defaultValue, options) {
        try {
            const res = this.get(key, options);
            return res === null ? defaultValue : res;
        } catch (err) {
            console.error(`An error occurred while getting previously stored value by key "${key}"`, { err });
            console.error(`Falling back to default value of "${JSON.stringify(defaultValue)}"`);
            return defaultValue;
        }
    }

    set(key, val, { prefix } = {}) {
        const storageKey = this._getKeyName(key, prefix);

        try {
            const serialized = JSON.stringify(val);
            this._storage.setItem(storageKey, serialized);
            this.reactiveStorageObject[key] = serialized;
        } catch (err) {
            console.error(`An error occurred while setting a value by key "${key}" to storage`, { err });
            throw err;
        }
    }

    remove(key, { prefix } = {}) {
        try {
            const storageKey = this._getKeyName(key, prefix);
            this._storage.removeItem(storageKey);
            delete this.reactiveStorageObject[key];
        } catch (err) {
            console.error(`An error occurred while removing key "${key}" from storage`, { err });
            throw err;
        }
    }

    _checkVersion() {
        if (this.get('version') !== this.currentVersion) {
            this._clearStorage();
            this.set('version', this.currentVersion);
        }
    }

    _clearStorage() {
        const keysToRemove = [];

        for (let i = 0; i < this._storage.length; i += 1) {
            const key = this._storage.key(i);
            const itemStartsWithPrefix = key.slice(0, this.prefix.length) === this.prefix;

            if (itemStartsWithPrefix) {
                keysToRemove.push(key);
            }
        }

        keysToRemove.forEach((k) => this._storage.removeItem(k));
    }

    _getKeyName(name, prefix = this.prefix) {
        return prefix + name;
    }

    static get checkAvailabilityLocalStorage() {
        try {
            window.localStorage.setItem('CHECK', '1');
            window.localStorage.removeItem('CHECK');
            return true;
        } catch (err) {
            console.warn('LocalStorage interface is not available', { err });
            return false;
        }
    }
}

const noop = () => {};

export class PromiseTimeoutError extends Error {
    /**
     * @param {number} timeout
     */
    constructor(timeout) {
        super(`Promise execution exceeds ${timeout}ms`);
    }
}

/**
 * @param {number} [timeout]
 * @param {Function} [onAny]
 * @param {Function} [onReject]
 * @param {Function} [onResolve]
 * @param {Function} [onTimeout]
 * @returns {Promise<*>}
 */
export default ({
    timeout = 0,
    onAny = noop,
    onReject = noop,
    onResolve = noop,
    onTimeout = noop,
} = {}) => {
    let resolve;
    let reject;
    let timeoutHandler;

    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });

    promise.resolve = (...args) => {
        clearTimeout(timeoutHandler);
        resolve(...args);
        onResolve();
        onAny();
    };

    promise.reject = (...args) => {
        clearTimeout(timeoutHandler);
        reject(...args);
        onReject();
        onAny();
    };

    if (timeout > 0) {
        timeoutHandler = setTimeout(
            () => {
                reject(new PromiseTimeoutError(timeout));
                onTimeout();
                onAny();
            },
            timeout,
        );
    }

    return promise;
};

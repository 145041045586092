import { AppStorage, CONSOLE_CONFIG, FORCE_LOGGING } from '@/plugins/AppStorage';
import { isDebugDomain } from '@/utils/debug';

import { allowedConsoleMethods, defaultConsoleConfig } from '@config/console.config';

const methods = AppStorage.getOr(CONSOLE_CONFIG, defaultConsoleConfig);

/**
 * Включаем автокомплит в IDEA
 *
 * @namespace console
 *   @property {(...args: unknown[]) => void} common
 *   @property {(...args: unknown[]) => void} critical
 *   @property {(...args: unknown[]) => void} desktop
 *   @property {(...args: unknown[]) => void} gevent
 *   @property {(...args: unknown[]) => void} mobile
 *   @property {(...args: unknown[]) => void} raw
 *   @property {(...args: unknown[]) => void} socket
 *   @property {(...args: unknown[]) => void} stupid
 *   @property {(...args: unknown[]) => void} unexpected
 */

let level = (
    process.env.CONSOLE_FORCE || isDebugDomain()
        ? 1
        : AppStorage.getOr(FORCE_LOGGING, 0)
);

const {
    log,
    error,
    trace,
    warn,
} = console;

const consoleMethods = {
    log,
    error,
    trace,
    warn,
};

export default () => {
    window.console = new Proxy({}, {
        get(obj, key) {
            if (allowedConsoleMethods.includes(key)) {
                const method = methods[key];
                if (method && (method.level || 0) <= level && method.enabled) {
                    try {
                        const func = consoleMethods[method.method];

                        if (!func) {
                            error('Unknown console function:', method, consoleMethods);
                            return undefined;
                        }

                        return (...args) => (!method.raw ? func(`${key}: `, ...args) : func(...args));
                    } catch (e) {
                        error(method);
                        error('method', method.method);
                        error('function', consoleMethods[method.method]);
                    }
                }
            } else if (methods[key] && methods[key].enabled) {
                console.unexpected(`Usage of ${key} is deprecated.`);
                return console.critical;
            }
            return () => {
            };
        },
        set(obj, key, value) {
            if (key === 'default') {
                AppStorage.remove(CONSOLE_CONFIG);
                AppStorage.remove(FORCE_LOGGING);
                return;
            }
            if (key === 'force') {
                level = 1;
                AppStorage.set(FORCE_LOGGING, value);
            }
            if (typeof value !== 'boolean') {
                return;
            }
            if (!methods[key]) {
                return;
            }
            methods[key].enabled = value;
            AppStorage.set(CONSOLE_CONFIG, methods);
        },
    });
};

const levels = Object.freeze({
    PRODUCTION: 0,
    DEVELOPMENT: 1,
});

export const defaultConsoleConfig = {
    log: {
        level: process.env.NODE_ENV === 'production' | levels.PRODUCTION, // eslint-disable-line no-bitwise
        deprecated: false,
        enabled: true,
        method: 'log',
    },
    error: {
        level: levels.DEVELOPMENT,
        deprecated: false,
        enabled: true,
        method: 'error',
    },
    debug: {
        level: levels.DEVELOPMENT,
        deprecated: false,
        enabled: true,
        method: 'log',
    },
    info: {
        level: levels.DEVELOPMENT,
        deprecated: false,
        enabled: true,
        method: 'log',
    },
    warn: {
        level: levels.DEVELOPMENT,
        deprecated: false,
        enabled: true,
        method: 'warn',
    },
    firebug: {
        level: levels.DEVELOPMENT,
        deprecated: false,
        enabled: true,
        method: 'warn', // ?
    },
    // // our methods
    critical: {
    // use in places, where real critical 'error's could occur
        level: levels.PRODUCTION,
        enabled: true,
        method: 'error',
    },
    socket: {
    // socket related
        level: levels.DEVELOPMENT,
        enabled: true,
        method: 'warn',
    },
    unexpected: {
    // use for unexpected behaviour cases
        level: levels.PRODUCTION,
        enabled: true,
        method: 'warn',
    },
    // // bundle loggers
    desktop: {
        level: levels.DEVELOPMENT,
        enabled: false,
        method: 'log',
    },
    mobile: {
        level: levels.DEVELOPMENT,
        enabled: false,
        method: 'log',
    },
    common: {
        level: levels.DEVELOPMENT,
        enabled: false,
        method: 'log',
    },
    stupid: {
    // / use for stupid debug related things
        level: levels.DEVELOPMENT,
        enabled: false,
        method: 'log',
    },
    gevent: {
        level: levels.DEVELOPMENT,
        enabled: false,
        method: 'log',
    },
    raw: {
        level: levels.DEVELOPMENT,
        deprecated: false,
        enabled: true,
        raw: true,
        method: 'log',
    },
    trace: {
        level: levels.DEVELOPMENT,
        deprecated: false,
        enabled: true,
        raw: true,
        method: 'trace',
    },
};

export const allowedConsoleMethods = Object.keys(defaultConsoleConfig)
    .filter((method) => !defaultConsoleConfig[method].deprecated);

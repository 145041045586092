export default {
    HOME: 'home',
    BETS_CUSTOM: 'bets-custom',
    BETS_CUSTOM_EVENT: 'custom-event',

    BETS_LIVE: 'live',
    BETS_PREMATCH: 'prematch',
    BETS: 'bets',
    BETS_SPORT: 'bets-sport',
    BETS_TOURNAMENT: 'bets-tournament',
    BETS_MATCH: 'bets-match',

    BETS_VSPORT: 'live-vsport',
    VIRTUAL_SPORT: 'virtual-sport',
    BETS_RESULT: 'results',
    BETS_RESULT_SPORT: 'results-sport',
    BETS_RESULT_TOURNAMENT: 'results-tournament',
    BETS_COUPONS: 'coupons',
    BETS_CYBERSPORT: 'esport',
    BETS_CYBERSPORT_SPORT: 'esport-sport',
    BETS_CYBERSPORT_TOURNAMENT: 'esport-tournament',
    BETS_CYBERSPORT_MATCH: 'esport-match',
    BETS_OUTRIGHTS_SPORT: 'outrights-sport',
    BETS_OUTRIGHTS_TOURNAMENT: 'outrights-tournament',
    CASINO: 'casino',
    CASINO_CATEGORY: 'casino-category',
    CASINO_PROMO: 'casino-promo',
    CASINO_WIDGET: 'casino-widget',
    CASINO_LIVE: 'casino-live',
    CASINO_FAVORITES: 'casino-favorites',
    CASINO_GAME: 'casino-game',
    CASINO_GAME_AVIATOR: 'casino-game-aviator',
    CASINO_GAME_FANTASY_SPORT: 'casino-game-fantasy-sport',
    CASINO_GAMES: 'casino-games',
    CASINO_GAMES_ONE_WIN: 'casino-games-one-win',
    CASINO_PROVIDER: 'casino-provider',
    CASINO_PROVIDERS: 'casino-providers',
    CASE: 'case',
    CASES: 'cases',
    CASES_LIST: 'cases-list',
    DETAILING: 'detailing',
    SEARCH_RESULT: 'search-result',
    FAVOURITES: 'favourites',
    FAVOURITES_EVENTS: 'favourites-events',
    FAVOURITES_TOURNAMENTS: 'favourites-tournaments',
    FAVOURITES_GAMES: 'favourites-games',
    FAVOURITES_CUSTOM_BETS: 'favourites-custom-bets',
    INVEST: 'invest',
    RULES: 'rules',
    RULE: 'rule',
    PROFILE: 'profile',
    PROFILE_EDIT: 'profile-edit',
    PROFILE_WALLETS: 'profile-wallets',
    WALLET_DEPOSIT: 'wallet-deposit',
    WALLET_WITHDRAWAL: 'wallet-withdrawal',
    PROFILE_BETS_HISTORY: 'profile-bets-history',
    PROFILE_BETS_HISTORY_COUPON: 'profile-bets-history-coupon',
    TV: 'tv',
    TV_COMING_SOON: 'tv-coming-soon',
    TV_NEW_EPISODES: 'tv-new-episodes',
    TV_CINEMA: 'tv-cinema',
    OAUTH: 'Oauth',
    ACCESS: 'access',
    TVBET: 'tvbet',
    BETGAMES: 'betgames',
    TWAINSPORT: 'twain-sport',
    TVBET_POKER: 'tvbet-poker',
    TVBET_POKER_PRE: 'tvbet-poker-pre',
    POKER: 'poker',
    STATS: 'stats',
    PWA_INSTRUCTION: 'PWA-instruction',
    LEADERBOARD: 'leaderboard',
    AUTH_TOKEN: 'auth-token',
    BONUSES: 'bonuses',
    MOBILE_RULES: 'mobile-rules',
    FREE_MONEY: 'free-money',
    FREE_MONEY_VK_AUTH: 'free-money-vk-auth',

    // used only in burger as visual dividers
    PSEUDO_ROUTE_BURGER_DIVIDER_1: 'divider1',
    PSEUDO_ROUTE_BURGER_DIVIDER_2: 'divider2',
};
